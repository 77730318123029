import { Button, Col, Flex, Image, Row, Spin } from 'antd'
import ShowDetailsVertical from 'Components/Order/ShowDetailsVertical'
import React from 'react'
import SeatDesign from './SeatDesign'
import { screen } from 'Assets'
import SeatSvg20 from './SeatSvg20'
import { IoMdAdd, IoMdRemove } from 'react-icons/io'
import { useNotificationContext } from 'Config/NotificationsContext'

const TicketMode = ({
    movieData,
    showData,
    thisTheatre,
    small,
    medium,
    large,
    totalSeatsSelected,
    bookedSeats,
    setBookedSeats,
    setTotalSeatsSelected,
    maxSeatSelectionAllowed,
    seatContainerRef,
    hallSeats,
    seatTypes,
    ticketVariety,
    totalPrice,
    seatLockingInProgress,
    lockSeats,
    setTimeLeft,
    seatNumberModal,
    setSeatNumberModal
}) => {

    const openNotification = useNotificationContext();

    return (
        <>
            <Row className={`px-4 booking-wrapper ${medium ? "row" : ""}`}>
                <Col span={4}>
                    <ShowDetailsVertical
                        title={movieData?.title}
                        image={movieData?.poster_image}
                        language={showData?.language_data}
                        theatre={thisTheatre}
                        showDate={showData?.date}
                        startTime={showData?.start_time}
                        formatType={showData?.format_type_data?.title}
                        screen={showData?.hall_data?.hall_no}
                    />
                </Col>
                <Col span={14} className="my-4 p-0">
                    <div className="p-0 m-0 w-100">
                        <Flex justify="center" ref={seatContainerRef} style={{ minHeight: "50vh", overflow: "auto" }}>
                            {hallSeats?.length > 0 ?
                                <Flex vertical style={{ width: "100%", margin: "10px", padding: 10, overflow: "auto", maxHeight: "80vh" }}>
                                    {hallSeats.map((seats, row) => {
                                        return (
                                            <Flex justify="center">
                                                {seats.map((seat, column) => {
                                                    return (
                                                        <SeatDesign
                                                            row={row}
                                                            column={column}
                                                            seat={seat}
                                                            allSeats={hallSeats}
                                                            totalSeatsSelected={totalSeatsSelected}
                                                            defaultColor={`${seat?.seatType_data?.color}75`}
                                                            color={seat?.seatType_data?.color}
                                                            bookedSeats={bookedSeats}
                                                            setBookedSeats={setBookedSeats}
                                                        />
                                                    )
                                                })}
                                            </Flex>
                                        )
                                    })}
                                    <Flex justify="center">
                                        <Image preview={false} src={screen} />
                                    </Flex>
                                </Flex>
                                :
                                <Flex justify="center" align="center">
                                    <Spin size="small" style={{ marginRight: 10 }} />
                                </Flex>
                            }
                        </Flex>
                        <Flex justify="center" align="center" gap={10}>
                            {seatTypes.map((scheme) => {
                                return (
                                    <Flex justify="center" align="center" gap={3}>
                                        <SeatSvg20 color={scheme.color} />
                                        <div className="mx-2 text-white">{scheme.title}</div>
                                    </Flex>
                                )
                            })}
                        </Flex>
                    </div>
                </Col>
                <Col span={6}>
                    <div className="mt-4 text-white">
                        {medium &&
                            <Flex justify="space-between" align="center" style={{ marginRight: "1.25rem", marginBottom: "1rem" }}>
                                <p style={{ marginBottom: 0 }}>No. of seats</p>
                                <Flex justify="space-between" gap={10} align="center">
                                    <Button
                                        className="rounded-circle"
                                        htmlType="default"
                                        type="default"
                                        style={{ borderRadius: 10, padding: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                                        disabled={totalSeatsSelected <= 1}
                                        onClick={() => {
                                            if (totalSeatsSelected - 1 < bookedSeats.length) {
                                                let array = [...bookedSeats]
                                                array.pop()
                                                console.log(array)
                                                setBookedSeats(array)
                                            }
                                            setTotalSeatsSelected(totalSeatsSelected - 1)
                                        }}
                                    >
                                        <IoMdRemove />
                                    </Button>
                                    <span className="px-4">{totalSeatsSelected}</span>
                                    <Button
                                        className="rounded-circle"
                                        htmlType="default"
                                        type="default"
                                        style={{ borderRadius: 10, padding: 10, display: "flex", justifyContent: "center", alignItems: "center" }}
                                        disabled={totalSeatsSelected >= maxSeatSelectionAllowed}
                                        onClick={() => setTotalSeatsSelected(totalSeatsSelected + 1)}
                                    >
                                        <IoMdAdd />
                                    </Button>
                                </Flex>
                            </Flex>}
                        <div style={{ marginRight: "1.25rem" }}>
                            {ticketVariety?.map((variety) => {
                                return (
                                    <Flex align="center" gap={5}>
                                        <SeatSvg20 color={variety.color} />
                                        <span className="text-white ms-2">{variety.quantity} &nbsp;{variety.type}  &nbsp;Ticket{variety.quantity > 1 ? "s" : ""}</span>
                                        {/* <span className="text-white">&nbsp;{variety.quantity} &nbsp;{variety.type}  &nbsp;Ticket{variety.quantity > 1 ? "s" : ""}{index === ticketVariety.length - 2 ? " & " : (index === ticketVariety.length - 1 ? "" : ", ")}</span> */}
                                    </Flex>
                                )
                            })}
                        </div>
                        <hr className={`${large ? "text-white me-5" : ""}`} />
                        <h4 className="mb-4">MUR {totalPrice}</h4>
                        <Flex justify="center" align="center" style={{ marginBottom: "1.25rem" }}>
                            <Button
                                className="bg-primary primary-button border border-primary px-4 me-0 me-sm-3"
                                disabled={seatLockingInProgress}
                                onClick={() => {
                                    if (bookedSeats.length > 0) {
                                        lockSeats()
                                        // setTimeLeft(5 * 60)
                                    } else {
                                        openNotification("Please select seats", null, "error")
                                    }
                                }}
                            >
                                {
                                    seatLockingInProgress ? <div className="d-flex gap-3 align-items-center">
                                        <Spin size="small" style={{ marginRight: 10 }} />
                                        <span>Processing ...</span>
                                    </div> : "Book Ticket"
                                }
                            </Button>
                        </Flex>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default TicketMode