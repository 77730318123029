import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import searchParamsEnum from 'Utils/searchParamsEnum';
// import { setPageNumber } from 'Redux/MasterReducer/crudSlices';
import { useCallback, useLayoutEffect } from 'react';


const CustomPagination = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    // const dispatch = useDispatch();
    const totalDataCount = useSelector(store => store.master.count);

    const onPageChange = useCallback((pageNumber) => {
        setSearchParams(params => {
            console.log({newPageNumber: pageNumber});
            params.set(searchParamsEnum.PAGE, pageNumber);
            // dispatch(setPageNumber({newPageNumber: pageNumber}))
            return params;
        })
    }, [setSearchParams])

    useLayoutEffect(()=>{
        let searchParamPage = searchParams.get('page');
        if (!searchParamPage) {
            onPageChange(1);
        }
    }, [onPageChange, searchParams])

    if (totalDataCount) {
        return (
            <>
                <Pagination
                    style={{padding: "10px", backgroundColor: "white", boxShadow: "rgba(0,0,0,0.2) 1px 2px 5px", borderRadius: 10}}
                    defaultCurrent={searchParams.get(searchParamsEnum.PAGE)}
                    current={searchParams.get(searchParamsEnum.PAGE)}
                    total={totalDataCount}
                    onChange={onPageChange}
                    showSizeChanger={false}
                    pageSize={50}
                />
            </>
        );
    } else {
        return <></>;
    }

};
export default CustomPagination;