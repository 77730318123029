import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Flex, Form, Input, Row, Select, TimePicker, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import { fetchHallsList, fetchLanguagesList, fetchProgramsList, fetchShowFormatTypeChoices, fetchAllDistributorsList, fetchClientProgramsList, fetchTheatresList, fetchSeatTypesForThisHall, fetchProgramTypeList, fetchSeatTypeList } from 'Api/commonApis';
import dayjs from 'dayjs';
import { SELECTED_DATE_FORMAT, START_TIME_FORMAT } from 'Redux/ShowReducer/showSlices';
import CheckIfPrequisitesAreAvailable from 'Components/Common/CheckIfPrequisitesAreAvailable';
import ProgramTypeSurchargeForm from 'Components/Common/Shows/ProgramTypeSurchargeForm';
import { useNotificationContext } from 'Config/NotificationsContext';
import { getSurchargeName, surchargeNamePrefix } from 'Components/CreateShow/utils';

const AddEditForm = ({ apiUrl }) => {
    const [notificationApi, contextHolder] = notification.useNotification();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const selectedClient = useSelector(store => store.shows?.selectedClient);

    const [programOptions, setProgramOptions] = useState(null);
    const [theatresOptions, setTheatresOptions] = useState(null);
    const [hallsOptions, setHallsOptions] = useState(null);
    const [languagesOptions, setLanguagesOptions] = useState(null);
    const [formatTypeOptions, setFormatTypeOptions] = useState(null);
    const [distributorOptions, setDistributorsOptions] = useState(null);
    const [seatTypes, setSeatTypes] = useState([]);
    const [selectedFormatType, setSelectedFormatType] = useState("");

    const [selectedTheatre, setSelectedTheatre] = useState(null);

    const formatTypeSelected = (selectedFormat, fullData) => {
        console.log(fullData)
        setSelectedFormatType(fullData);
    }

    const openNotification = useNotificationContext();

    const currentFormMode = useSelector(store => store.master?.currentFormMode);
    const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
    const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
    const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
    const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
    const currentRecordData = useSelector(store => store.master?.currentRecordData);

    useEffect(() => {
        const fetchHallsOptions = async (theatreId) => {
            try {
                let search_param = theatreId ? `?theatre=${theatreId}` : ""
                const list = await fetchHallsList(search_param);
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l?.hall_no?.toString() }))
                    setHallsOptions(options);
                } else {
                    throw new Error("Fetch Halls did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Hall Options", err.message, "error");
                } else {
                    openNotification("Hall Options", "Something went wrong while fetching user options", "error")
                }
            }
        }

        fetchHallsOptions(selectedTheatre);
    }, [selectedTheatre, openNotification])

    useEffect(() => {
        const fetchClientProgramOptions = async () => {
            try {
                let search_param = selectedClient ? `?client=${selectedClient}` : ""
                const list = await fetchClientProgramsList(search_param);
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title }))
                    setProgramOptions(options);
                } else {
                    throw new Error("Fetch Programs did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Program Options", err.message, "error");
                } else {
                    openNotification("Program Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        const fetchTheatreOptions = async () => {
            try {
                let search_param = selectedClient ? `?client=${selectedClient}` : ""
                const list = await fetchTheatresList(search_param);
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title }))
                    setTheatresOptions(options);
                } else {
                    throw new Error("Fetch Theatres did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Theatres Options", err.message, "error");
                } else {
                    openNotification("Theatres Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        const fetchLanguagesOptions = async () => {
            try {
                const list = await fetchLanguagesList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title }))
                    setLanguagesOptions(options);
                } else {
                    throw new Error("Fetch Languages did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Language Options", err.message, "error");
                } else {
                    openNotification("Language Options", "Something went wrong while fetching user options", "error")
                }
            }
        }
        // const fetchFormatTypesOptions = async () => {
        //     try {
        //         const list = await fetchShowFormatTypeChoices();
        //         if (Array.isArray(list)) {
        //             const options = list.map(l => ({ value: l[0], label: l[0] }));
        //             setFormatTypeOptions(options);
        //         } else {
        //             throw new Error("Fetch FormatTypes did not return a list");
        //         }
        //     } catch (err) {
        //         console.log({ err })
        //         if (err.message) {
        //             openNotification("Format Type Options", err.message, "error");
        //         } else {
        //             openNotification("Format Type Options", "Something went wrong while fetching user options", "error")
        //         }
        //     }
        // }
        const fetchFormatTypesOptions = async () => {
            try {
                const list = await fetchProgramTypeList();
                console.log({ list });
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.title, surcharge: l.surcharge }));
                    setFormatTypeOptions(options);
                } else {
                    throw new Error("Fetch FormatTypes did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Format Type Options", err.message, "error");
                } else {
                    openNotification("Format Type Options", "Something went wrong while fetching format type options", "error")
                }
            }
        }
        const fetchDistributorsOptions = async () => {
            try {
                const list = await fetchAllDistributorsList();
                if (Array.isArray(list)) {
                    const options = list.map(l => ({ value: l.id, label: l.name }))
                    setDistributorsOptions(options);
                } else {
                    throw new Error("Fetch Distributors did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Distributor Options", err.message, "error");
                } else {
                    openNotification("Distributor Options", "Something went wrong while fetching distributor options", "error")
                }
            }
        }

        const fetchSeatTypesList = async (hallId) => {
            try {
                const list = await fetchSeatTypeList();
                if (Array.isArray(list)) {
                    setSeatTypes(list);
                } else {
                    throw new Error("Fetch Halls did not return a list");
                }
            } catch (err) {
                console.log({ err })
                if (err.message) {
                    openNotification("Seat Types", err.message, "error");
                } else {
                    openNotification("Seat Types", "Something went wrong while fetching seat types", "error")
                }
            }
        }

        fetchDistributorsOptions();
        fetchLanguagesOptions();
        fetchClientProgramOptions();
        fetchTheatreOptions();
        fetchFormatTypesOptions();
        fetchSeatTypesList();
        // if (currentFormMode === FormModesEnum.EDIT) {
        // fetchHallsOptions(null)
        // }
    }, []);

    // useEffect(() => {
    //     if (editRecordStatus === asyncStatuses.SUCCESS) {
    //         form.resetFields();
    //     } else if (addRecordStatus === asyncStatuses.SUCCESS) {
    //         form.resetFields();
    //     }
    //     return form.resetFields();
    // }, [editRecordStatus, addRecordStatus, form]);

    useEffect(() => {
        // console.log({ fetchARecord, currentRecordData });
        let currentRecordDataCopy = JSON.parse(JSON.stringify(currentRecordData));
        if (currentRecordDataCopy.start_time) {
            let timeString = currentRecordDataCopy.start_time;
            currentRecordDataCopy.start_time = dayjs(timeString, START_TIME_FORMAT);
        }
        if (currentRecordDataCopy.date) {
            currentRecordDataCopy.date = dayjs(currentRecordDataCopy.date, SELECTED_DATE_FORMAT);
        }
        // if (currentRecordDataCopy.hall) {
        //     fetchSeatTypesForHall(currentRecordDataCopy.hall)
        // }
        form.resetFields();
        form.setFieldsValue(currentRecordDataCopy);
        setSelectedFormatType(currentRecordDataCopy?.format_type_data);
        setSelectedTheatre(currentRecordDataCopy.theatre);
    }, [fetchRecordStatus, currentRecordData]);

    useEffect(() => {
        if (currentFormMode === FormModesEnum.ADD) {
            form.resetFields();
        }
    }, [form, currentFormMode]);

    useEffect(() => {
        if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
            if (!currentEditViewFormId) {
                openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
                return;
            } else {
                dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
            }
        }
    }, [currentEditViewFormId, currentFormMode, dispatch]);

    const addNew = (val) => {
        dispatch(addARecord({ apiUrl: apiUrl, data: val }));
    }

    const edit = (val) => {
        dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
    }

    const onFinish = (val) => {
        val.is_seated_event = !!val.is_seated_event;
        val.pos_only = !!val.pos_only;
        console.log({ val });
        val.start_time = val.start_time.format(START_TIME_FORMAT);
        val.date = val.date.format(SELECTED_DATE_FORMAT)
        console.log({ val });

        let surcharges = {};
        let surchargeValue = selectedFormatType?.surcharge || 0;

        console.log({selectedFormatType, surchargeValue, seatTypes})
        if (surchargeValue > 0) {
            for (let st of seatTypes) {
                console.log({st})
                surcharges[getSurchargeName(st)] = surchargeValue;
            }
        }
        console.log({surcharges, surchargeNamePrefix})

        for (let key in val) {
            if (key.includes(surchargeNamePrefix)) {
                if (val[key] !== "" && val[key]) {
                    let sVal = 0;
                    try {
                        sVal = parseFloat(val[key]);
                        surcharges[key] = sVal;
                    } catch (e) {
                        console.error(e);
                    }
                };
            }
        }
        console.log({surcharges})

        val.surcharges = surcharges;

        if (currentFormMode === FormModesEnum.ADD) {
            addNew(val);
        } else if (currentFormMode === FormModesEnum.EDIT) {
            edit(val);
        } else {
            console.log("No suitable mode found");
        }
    }

    return (
        <>
            {contextHolder}
            <CheckIfPrequisitesAreAvailable
                thisModel="Shows"
                requiredData={[
                    { data: theatresOptions, field: "theatre" },
                    { data: hallsOptions, field: "hall" },
                    { data: languagesOptions, field: "language" },
                    { data: formatTypeOptions, field: "Format Type" },
                    { data: distributorOptions, field: "distributor" },
                    { data: programOptions, field: "program" },
                ]}
            >
                <Form layout="vertical"
                    requiredMark={true}
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    disabled={currentFormMode === FormModesEnum.VIEW}
                >
                    <Form.Item
                        name="client_program"
                        label="Program"
                        rules={[
                            {
                                required: true,
                                message: "Program is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(programOptions) ? false : true}
                            options={programOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="theatre"
                        label="Theatres"
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(theatresOptions) ? false : true}
                            options={theatresOptions}
                            onChange={(value) => {
                                setSelectedTheatre(value);
                                if (value) {
                                    form.setFieldValue("hall", "");
                                    seatTypes?.forEach?.(st => form.setFieldValue(getSurchargeName(st), ""));
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="hall"
                        label="Hall"
                        rules={[
                            {
                                required: true,
                                message: "Hall is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(hallsOptions) ? false : true}
                            options={hallsOptions}
                        // onChange={(value) => {
                        //     fetchSeatTypesForHall(value)
                        // }}
                        />
                    </Form.Item>
                    {/* {seatTypes.length > 0 &&
                    <div>
                        Enter Seat Price
                    </div>
                }
                <Row>
                    {seatTypes.map((type) => {
                        return (
                            <Form.Item
                                name={`seat_type_${type.id}`}
                                label={type.title}
                                rules={[
                                    { required: true, message: 'Seat Price is required.' }
                                ]}
                                style={{
                                    width: 100,
                                    marginRight: 10,
                                }}
                            >
                                <Input type="number" />
                            </Form.Item>
                        )
                    })}
                </Row> */}
                    <Form.Item
                        name="date"
                        label="Date (default is today)"
                        rules={[
                            {
                                required: false,
                                message: "Date is required"
                            }
                        ]}
                    >
                        <DatePicker format={SELECTED_DATE_FORMAT} />
                    </Form.Item>
                    <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[
                            {
                                required: true,
                                message: "Start Time is required"
                            }
                        ]}
                    >
                        <TimePicker format={START_TIME_FORMAT} />
                    </Form.Item>
                    <Form.Item
                        name="format_type"
                        label="Format Type"
                        rules={[
                            {
                                required: true,
                                message: "Format Type is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            onSelect={formatTypeSelected}
                            loading={Array.isArray(formatTypeOptions) ? false : true}
                            options={formatTypeOptions}
                            optionRender={(option) => {
                                return <Flex justify='space-between'>
                                    <div>{option?.data?.label}</div>
                                    <div><small style={{ color: "gray" }}>MUR {option?.data?.surcharge}</small></div>
                                </Flex>
                            }}
                        />
                    </Form.Item>
                    <ProgramTypeSurchargeForm selectedFormatType={selectedFormatType} showId={currentEditViewFormId} _seatTypes={seatTypes} _setSeatTypes={setSeatTypes} />
                    <Form.Item
                        name="language"
                        label="Language"
                        rules={[
                            {
                                required: true,
                                message: "Language is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: 200,
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(languagesOptions) ? false : true}
                            options={languagesOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        name="distributor"
                        label="Distributor"
                        rules={[
                            {
                                required: true,
                                message: "Distributor is required"
                            }
                        ]}
                    >
                        <Select
                            showSearch
                            style={{
                                width: "100%",
                            }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label?.toLowerCase?.() ?? '').includes(input?.toLowerCase?.())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            loading={Array.isArray(distributorOptions) ? false : true}
                            options={distributorOptions}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Is Seated Event?"
                        name="is_seated_event"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox />
                    </Form.Item>

                    <Form.Item
                        label="Is POS Only?"
                        name="pos_only"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Checkbox />
                    </Form.Item>
                    {
                        currentFormMode === FormModesEnum.ADD ? <Form.Item>
                            {
                                addRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Submitting
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Submit
                                    </Button>
                            }
                        </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
                            {
                                editRecordStatus === asyncStatuses.LOADING ?
                                    <Button type="primary" htmlType="submit" disabled>
                                        <LoadingOutlined />
                                        Updating
                                    </Button> :
                                    <Button type="primary" htmlType="submit" >
                                        Update
                                    </Button>
                            }
                        </Form.Item> : <></>
                    }

                </Form >
            </CheckIfPrequisitesAreAvailable>
        </>
    );
};
export default AddEditForm;