import getContrastingColor from "Utils/getContrastingColor";
import { Flex, Checkbox } from "antd";

const commonStyles = {
    margin: 2,
    width: 30,
    height: 30,
    padding: 2,
    position: "relative",
}

const SeatHtml = ({ color, seat_id, seat_label, is_seat }) => {

    return <Flex
        key={seat_id}
        align="end"
        justify="center"
        className=""
        style={{
            ...commonStyles,
            border: is_seat ? "1px solid gray" : "none",
            borderRadius: 2,
            color: color ? getContrastingColor(color) : null,
            backgroundColor: color ? color : null
        }}
    >
        <small>{seat_label}</small>
    </Flex>
}

export default SeatHtml;